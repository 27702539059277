import React, {FunctionComponent} from 'react';
import {Link} from 'gatsby';
import HtmlHead from '../components/layout/HtmlHead';
import {DialogDisclosure, useDialogState} from 'reakit/Dialog';
import Modal from '../components/Modal';
import Anchor from '../components/Anchor';
import ExternalLinkModalContent from '../components/ExternalLinkModalContent';
const SitemapPage: FunctionComponent = () => {
    require('../styles/global.scss');
    require(`../styles/${process.env.THEME}/global.scss`);
    const links: Array<{to: string, title: string}> = require(`../content/${process.env.THEME}/sitemap.json`).links;
    const dialog = useDialogState();
    return (
        <>
            <HtmlHead pageTitle={'Sitemap'}/>
            <div className='px-4 lg:flex lg:stackx-8 lg:mt-12 lg:px-20 max-w-screen-2xl mx-auto content page relative'>
                <main className='w-full lg:w-3/4'>
                    <h1 className={'text-4xl font-bold my-6'}>{'Sitemap'}</h1>
                    <div className={'my-8'}>
                        <ul>
                            {
                                links.map((link) => {
                                    return (

                                        !(link.to === 'https://www.sanofi.nl/nl/contact') ?

                                            <li>
                                                <Link to={link.to}>{link.title}</Link>
                                            </li>

                                            :
                                            <li>
                                                <DialogDisclosure {...dialog}>
                                                    <Anchor to={link.to}>
                                                        {link.title}
                                                    </Anchor>
                                                </DialogDisclosure>
                                                <Modal dialog={dialog}>
                                                    <ExternalLinkModalContent dialog={dialog} externalLink={link.to}/>
                                                </Modal>
                                            </li>


                                    );
                                })
                            }
                        </ul>
                    </div>
                </main>
            </div>
        </>
    );
};

export default SitemapPage;
